var msg = new Array();
msg['ERR_PRIVACY']='Sie müssen Datenschutzerklärung akzeptieren';
msg['ERR_CONTRACT']='Sie müssen die Bedingungen des Dropshippping-Vertrags akzeptieren';
msg['ERR_MISSINGFIELDS']='Füllen Sie alle Felder aus';
msg['ERR_FILLEMAILFIELD']='Füllen Sie das E-Mail-Feld';
msg['ERR_USEREXISTS']='User existiert';
msg['ERR_INVALIDMAIL']='Ungültige E-Mail-Adresse';
msg['ERR_CLIENTMANDATORYFIELDS']='Füllen Sie alle Pflichtfelder';
msg['ERR_NOSIZESELECTED']='Sie müssen eine Größe auswählen';
msg['ERR_LOGINREQUIRED']='Login erforderlich';
msg['ERR_LOGININVALID']='Ungültige E-Mail oder Passwort';
msg['ERR_CARRIERMISSING']='Sie müssen eine Versandart zuerst wählen';
msg['ERR_CARRIER_COST_MISSING']='Keine Gebühr für ausgewählte Land Träger und damit Gewicht';
msg['ERR_SHORTPASSWORD']='Das Passwort muss mindestens :length Zeichen lang sein';
msg['ERR_PASSWORDCONFIRM']='Le password non coincidono';
msg['ERR_PASSWORDCOMPLEX']='Password must be at least 8 characters long and contain both uppercase and lowercase characters';
msg['ERR_WRONG_TOKEN']='Incorrect password recovery code';
msg['ERR_SYNC2FASHION_URL']='Invalid URL, must NOT start with http(s)://';
msg['ERR_ZIP_FORMAT']='Die Postleitzahl muss aus genau 5 Ziffern bestehen';
msg['MSG_ADDEDTOWISHLIST']='Produkt auf Ihrer Wunschliste hinzugefügt';
msg['MSG_REMOVEDTOWISHLIST']='Produkt von der Wunschliste entfernt';
msg['MSG_REMOVEDFROMCART']='Produkt aus dem Warenkorb entfernt';
msg['MSG_DEFERRED_DISPATCH_COMPUTATION']='Nur um Reservierung Modus wird aktiviert und Versandgebühren werden Sie per E-Mail gesendet';
msg['MSG_ADDEDTOCART']='Produkte in den Korb einfügen';
msg['MSG_SENDER_PRODUCTSHARE']='Ihr Name';
msg['MSG_WAIT']='Warten Sie bitte ...';
msg['MSG_MESSAGE_PRODUCTSHARE']='Nachricht';
msg['MSG_MAILSENT']='E-Mail gesendet';
msg['MSG_SUBJECT_PRODUCTSHARE']=' etwas mit Ihnen geteilt';
msg['MSG_MIXED_INTANGIBLE']='Dieses Produkt kann nicht zusammen mit Produkten gekauft werden Sie bereits in Ihrem Warenkorb';
msg['MSG_MIXED_CART']='Dieses Produkt kann nicht zusammen mit Produkten in Ihrem Warenkorb gekauft werden, weil es in ein anderem Lager ist';
msg['MSG_INSUFFICIENT_STOCK']='Entschuldigung, im Moment haben wir nicht genug Verfügbarkeit der Produkte';
msg['MSG_APPLIEDVATCHANGED']='After billing data upgrade, VAT applied to your order changed. Check the new amount ones page gets reloaded';
msg['MSG_GENERIC_ERROR']='Leider ist ein Fehler aufgetreten';
msg['MSG_COUPON_ERROR']='Codice coupon inserito non valido o inserito precedentemente';
msg['MSG_COUPON_VALID']='Codice coupon inserito validato con successo';
msg['MSG_RECOVEREMAILSENT']='Überprüfen Sie Ihre E-Mail und klicken Sie auf dem Link, um die Änderung zu bestätigen';
msg['MSG_RECOVEREMAILSENT_ERROR']='Achtung: Sie sind mit dieser E-Mail-Adresse nicht angemeldet oder Sie setzen Ihr Passwort bereits zurück';
msg['MSG_EXTRAUELIMIT']='Der Mindestbetrag soll nicht niedriger als 500.00€ sein ';
msg['MSG_RULE_REJECT']='Product cannot be added to cart because of rule :rule';
msg['LABEL_ARTICLES']='produkte';
msg['LABEL_WARNING']='Warnung';
msg['LABEL_NOTAPPLICABLE']='Nicht anwendbar';
msg['LABEL_ALREADYREGISTEREDUSERES']='Already registered users';
msg['LABEL_SENTINVITES']='Sent invites';
msg['LABEL_NOTSENTINVITES']='Unsent invites';
msg['LABEL_INVITES_ALREADY_SENT']='Users already invited';
msg['LABEL_SUBMIT']='Submit';
msg['LABEL_SELECTALL']='Select all';
msg['MSG_MESSAGE_SENT']='Nachricht erfolgreich gesendet';
msg['MSG_SUBSCRIBED'] = 'Erfolgreich angemeldet';
msg['MSG_PROFILECONSENT_SAVED'] = 'Wir haben ihre Kontaktvoreinstellungen aktualisiert';
msg['MSG_PASSWORD_NOT_EQUAL'] = 'passwords don\'t match';
msg['MSG_PASSWORD_MODIFIED'] = 'Password modified successfully';
msg['DELETEALLPRODUCTSERROR']='Fehler beim Entfernen von Produkten aus dem Warenkorb';
msg['MSG_DELETEALLPRODUCTS']='Sind Sie sicher, dass Sie alle Produkte aus dem Warenkorb entfernen möchten?';
msg['MSG_PEC_SDI_INCOMPLETE']='Geben Sie mindestens ein Feld zwischen PEC und SDI ein';
msg['MSG_ALREADY_RETURNED']='Rückgabeanforderung existiert bereits';
msg['MSG_RETURN_REQUEST_SUCCESS'] = 'Return Request erfolgreich erstellt';
msg['MSG_SAVE_SUCCESS'] = 'Speichern erfolgreich';
msg['MSG_ATTACHMENT_UPLOADED'] = 'Attachment uploaded successfully';
msg['ERR_ATTACHMENT_UPLOAD_FAIL'] = 'File could not be uploaded';
msg['MSG_ATTACHMENT_DELETED'] = 'Attachment deleted';
msg['ERR_NO_ATTACHMENT_TO_DELETE'] = 'No attachment to delete found';
msg['login.user.unexisting']='User does not exist';
msg['login.user.passwordnotcomplexenought']=msg['ERR_PASSWORDCOMPLEX'];
msg['You can only have one subscription active.']='You can only have one subscription active';
msg['msg.order.vatchanged']='Billing country must not be changed';
msg['msg.form.invalidtype'] = 'Es ist ein Fehler aufgetreten. Bitte kontaktieren Sie die Verwaltung und melden Sie den folgenden Fehler: "Kontaktart ungültig oder abwesend."';
msg['msg.form.invalidemail'] = 'Ungültige oder fehlende E-Mail';
msg['msg.form.invalidrecipients'] = 'Es ist ein Fehler aufgetreten. Bitte wenden Sie sich an die Verwaltung und melden Sie den folgenden Fehler: "Kontaktempfänger ungültig oder abwesend."';

var portal_livestock_available='AVAILABLE';
var portal_livestock_soldout='SOLD OUT';

var activity_online_shop = 'Online-Shop';
var activity_shop = 'Shop';
var activity_private_sales = 'Private Verkaufe';
var activity_outlet = 'Outlet';
var activity_online_outlet = 'Online Outlet';
var activity_store_chain = 'Ladenkette';
var activity_purchasing_group = 'Einkauf Gruppe';
var activity_broker = 'Broker/Handelsvertreter';
var activity_ebay_amazon = 'Shop Ebay/Amazon';
var activity_other = 'Anders';

var general='General';
var general_personaldata='Personal data';
var general_olduser='Registered user';
var general_newuser='New user';
var general_username="Username";
var general_password="Password";
var general_contacts='Contacts';
var general_choosesize="Choose at least one size";
var general_phone='Phone Nr';
var general_skype='Skype';
var general_website='WebSite';
var general_fax='Fax';
var general_firstname='First name';
var general_lastname='Last name';
var general_company='Company';
var general_birth='Date of birth';
var general_code='Code';
var general_model='Model';
var general_quantity='Quantity';
var general_availability='Availability';
var general_price='Price';
var general_warning='Warning';
var general_dispatchto='Company name/Adressee';
var general_careof='C/O';
var general_vat='VAT';
var general_address='Address';
var general_addresstype='Address type';
var general_addressnumber='Number';
var general_city='City';
var general_prov='Prov';
var general_zip='ZIP';
var general_mobile='Mobile';
var general_notes='Notes';
var general_orders='Ordini';
var general_fgfdiscounts='Friends and discounts';
var general_myprofile='My Profile';
var general_pieces='pc';
var general_size_table='Size Table';
var general_details='Details';
var general_invoice='Invoice';
var general_creditnote='Gutschrift';
var general_cart= 'Shopping Cart';
var general_country='Country';
var general_carrier='Carrier';
var general_dispatch_address='Dispatch Address';
var general_invoice_address='Invoice Address';
var general_receive_newsletter='Receive newsletter';
var general_copydispatchfrombilldata='Kopieren Sie aus Verrechnungdaten';
var general_instructions="Anweisungen";
var general_add="Hinzufügen";
var general_status="Status";
var general_toomanyfilters="Die maximal zulässige Anzahl von Filtern wurde erreicht";

var help_addresstype='Via, Piazza, Corso, ...';
var help_address='Street name';
var help_dispatchmobile='This number will be used by the carrier';
var help_ordercfpiva='A blank value will automatically add a 20% VAT to the order value';
var help_cf='Insert your SSN';

var buttons_save='Save';
var buttons_changepw='Change password';
var buttons_close='Close';
var buttons_continue='Continue';
var buttons_confirm='Confirm';
var buttons_cancel='Cancel';
var buttons_invite='Invita';
var buttons_continueshopping='Continue with shopping';
var buttons_gocheckout='Bestätigen';

var messages_product_soldout='Product sold out';
var messages_noselection='No selection';
var messages_invaliddata='Invalid data';
var messages_recoverpassword='Recover password';
var messages_recoverpassword_info='Insert the email you subscribed with<br/>You will receive an email with the istructions to get a recover your password';
var messages_invitefriends='Invite your friends';
var messages_confirmpassword='Confirm password';
var messages_sendingmail='Sending mail...';
var messages_mailsent='Email sent';
var messages_password='Password';
var messages_confirmpassword='Password confirm';
var messages_requiredfield='Pflichtfeld';

var subscribe_personaldata='Personal data';
var subscribe_privacyintro='You have to accept the following condition to proceed with the subscription';
var subscribe_privacydisclaimer='You have to accept the privacy displaimer to proceed with the subscription.'
var subscribe_privacy1='I accept the pricacy disclaimer';
var subscribe_privacy2='I accept that my personal data can be used by other firms for marketing campaigns (optional)';

var order_status='Status'
var order_date='Date'
var order_dispatchdate='Dispatched';

var returnedgoods_requests="Rückwaren Anfrage";
var returnedgoods_motivations="Rückwaren Motivation";
var returnedgoods_requestcode="Rückwaren Kode";
var returnedgoods_requestdate="Datum der Anfrage Date";
var returnedgoods_motivations_1="Das Produkt gefällt mir nicht";
var returnedgoods_motivations_2="Das Produkt ist zu groß/klein";
var returnedgoods_motivations_3="Das Produkt ist geschädigt";
var returnedgoods_motivations_4="Das erhiletes Produkt stimmt nicht mit dem gewünschten Produkt überein";
var returnedgoods_motivations_5="Das Produkt ist nicht in der Packung";
var returnedgoods_motivations_other="Anders";

var i18nOrderStatus = new Array();
i18nOrderStatus[0]='Pending';
i18nOrderStatus[1]='In payment';
i18nOrderStatus[2]='Confirmed';
i18nOrderStatus[3]='Sent';
i18nOrderStatus[4]='Delivered';
i18nOrderStatus[5]='Booked';
i18nOrderStatus[6]='Dropshipping';
i18nOrderStatus[2000]='Cancelled';
i18nOrderStatus[2001]='Error';
i18nOrderStatus[3001]='In progress';
i18nOrderStatus[3002]='Ready';

i18nOrderStatus[1000]='Payment Cancelled';
i18nOrderStatus[2002]='Payment verification failed';
i18nOrderStatus[2003]='Refunded';
i18nOrderStatus[2005]='Rejected';
i18nOrderStatus[3003]='Not Shippable';
i18nOrderStatus[5001]='Not authorized';
i18nOrderStatus[5002]='Authorized';
i18nOrderStatus[5003]='Dropshipping - Growing';